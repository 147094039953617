const pages = [
  {
    title: 'aboutUs',
    href: '/apie-mus',
    topBar: true,
    footer: true,
  },
  {
    title: 'partnerSearch',
    href: '/teniso-partneris',
    topBar: true,
    footer: true,
  },
  {
    title: 'tournaments',
    href: '/turnyrai',
    topBar: true,
    footer: true,
  },
  {
    title: 'coaches',
    href: '/teniso-treneriai',
    topBar: true,
    footer: true,
  },
  {
    title: 'blogNews',
    href: '/naujienos',
    topBar: true,
    footer: true,
  },
  {
    title: 'rent',
    href: '/nuoma',
    topBar: true,
    footer: true,
  },
  {
    title: 'FAQ',
    href: '/faq',
    topBar: false,
    footer: true,
  },
  {
    title: 'sponsors',
    href: '/remejai',
    topBar: false,
    footer: true,
  },
  {
    title: 'support',
    href: '/parama',
    topBar: false,
    footer: true,
  },
  {
    title: 'contacts',
    href: '/kontaktai',
    topBar: true,
    footer: true,
  },
  {
    title: 'privacy',
    href: '/privatumo-politika',
    topBar: false,
    footer: true,
  },
];

export default pages;
