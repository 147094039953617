import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PortableText } from '@portabletext/react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Typography, useMediaQuery } from '@mui/material';
import { AuthorCard } from 'components/Cards';
import copy from 'assets/icons/copy.svg';
import igDark from 'assets/icons/igDark.svg';
import fbDark from 'assets/icons/fbDark2.svg';
import linkedin from 'assets/icons/linkedin.svg';
import urlFor from 'utils/image';
import { useEffect } from 'react';

const myPortableTextComponent = ({ children }) => {
  return <span>{children}</span>;
};

const Content = ({ data }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);
  const [copied, setCopied] = useState(false);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  useEffect(() => {
    if (data) {
      setLoaded(true);
    }
  }, [data]);

  const { mainImage, body, author } = data;

  if (!loaded) {
    return null;
  }

  return (
    <Box>
      <Box
        sx={{
          maxWidth: '864px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          margin: '0 auto',
        }}
      >
        <Box
          sx={{
            h4: {
              fontWeight: 700,
              color: theme.palette.darkGrey[700],
              fontSize: { xs: '20px', md: '24px' },
              margin: '16px 0',
            },
            p: {
              color: theme.palette.darkGrey[600],
              fontSize: { xs: '16px', md: '18px' },
              margin: '16px 0',
            },
            blockquote: {
              color: theme.palette.darkGrey[600],
              fontSize: { xs: '18px', md: '20px' },
              margin: '32px 0',
              fontStyle: 'italic',
              lineHeight: '1.5',
              fontWeight: 500,
              borderLeft: `2px solid ${theme.palette.orange[700]}`,
              paddingLeft: '20px',
            },
            margin: 0,
          }}
        >
          <PortableText value={body} components={myPortableTextComponent} />
        </Box>
        <Box
          marginTop={'48px'}
          paddingTop={'24px'}
          borderTop={`1px solid ${theme.palette.darkGrey[200]}`}
          display={'flex'}
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent={'space-between'}
        >
          <AuthorCard
            avatar={author?.image ? urlFor(author?.image) : ''}
            jobTitle={author?.bio}
            name={author?.name}
          />
          <Box
            display={'flex'}
            height={'40px'}
            sx={{
              marginTop: { xs: '24px', md: 0 },
              justifyContent: { xs: 'space-between', md: 'flex-end' },
            }}
          >
            <Box
              sx={{
                width: '200px',
                display: 'flex',
                cursor: 'pointer',
                border: `1px solid ${theme.palette.darkGrey[300]}`,
                borderRadius: '8px',
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  backgroundColor: theme.palette.darkGrey[100],
                },
              }}
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 1000);
              }}
            >
              <Box
                component={'img'}
                src={copy}
                alt="copy"
                width={'20px'}
                height={'20px'}
              />
              <Typography
                variant="body3"
                sx={{
                  color: theme.palette.darkGrey[700],
                  marginLeft: '8px',
                  fontWeight: 600,
                }}
              >
                {copied ? t('copied') : t('copyLink')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                component={'a'}
                href={'https://www.facebook.com/groups/tenisopartneris'}
                target="_blank"
                width={'40px'}
                height={'40px'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                border={`1px solid ${theme.palette.darkGrey[300]}`}
                borderRadius="8px"
                marginLeft={'12px'}
                sx={{
                  opacity: 0.5,
                  ':hover': {
                    opacity: 1,
                  },
                }}
              >
                <Box component={'img'} src={fbDark} alt="facebook" />
              </Box>
              <Box
                component={'a'}
                href={'https://www.instagram.com/tenisopartneris/'}
                target="_blank"
                width={'40px'}
                height={'40px'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                border={`1px solid ${theme.palette.darkGrey[300]}`}
                borderRadius="8px"
                marginLeft={'12px'}
                sx={{
                  opacity: 0.5,
                  ':hover': {
                    opacity: 1,
                  },
                }}
              >
                <Box component={'img'} src={igDark} alt="ig" />
              </Box>
              <Box
                component={'a'}
                href={'https://www.linkedin.com/company/teniso-partneris'}
                target="_blank"
                width={'40px'}
                height={'40px'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                border={`1px solid ${theme.palette.darkGrey[300]}`}
                borderRadius="8px"
                marginLeft={'12px'}
                sx={{
                  opacity: 0.5,
                  ':hover': {
                    opacity: 1,
                  },
                }}
              >
                <Box component={'img'} src={linkedin} alt="linkedin" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Content;
