import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Box,
  Button as ButtonMui,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import logoSmall from 'assets/logo/tp-logo.svg';
import globe02 from 'assets/icons/globe02.svg';
import chevronDown from 'assets/icons/chevronDown.svg';
import avatarWhite from 'assets/images/defaultAvatar-white.svg';
import hamburger from 'assets/icons/hamburger.svg';
import { NavItem } from './components';
import Button from 'components/Button';
import { get } from 'api';
import Notification from 'system/layouts/Main/components/Topbar/components/Notifications/Notification';

const dropdownNav = [
  {
    title: 'profile',
    href: '/info',
  },
  {
    title: 'myAccount',
    href: '/redaguoti-profili',
  },
  {
    title: 'logout',
  },
];

const dropdownLng = [
  {
    title: 'LT',
    lng: 'lt',
  },
  {
    title: 'EN',
    lng: 'en',
  },
];

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [lngAnchorEl, setLngAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const lngOpen = Boolean(lngAnchorEl);
  const [currentUser, setCurrentUser] = useState([]);
  const token = localStorage.getItem('token');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLngClick = (event) => {
    setLngAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLngClose = () => {
    setLngAnchorEl(null);
  };

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    window.location.reload();
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await get(`/accounts/myavatar`);
        const data = await response.json();
        setCurrentUser(data);
      } catch (err) {
        console.error(err.message);
      }
    };
    getUser();
  }, []);

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Box
        display={'flex'}
        onClick={() => navigate('/')}
        width={{ xs: 55, md: 55 }}
        sx={{ cursor: 'pointer' }}
      >
        <Box
          component={'img'}
          alt="logo"
          src={logoSmall}
          height={1}
          width={1}
        />
      </Box>
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          marginLeft: { xs: '0px', md: '0' },
          justifyContent: 'end',
        }}
        maxWidth={'1080px'}
        width={'100%'}
        alignItems={'center'}
      >
        <Box display="flex" alignItems={'center'} gap={'32px'}>
          {pages
            .filter((page) => page.topBar)
            .map((page, i) => (
              <NavItem key={i} id={page.title} page={page} />
            ))}
        </Box>
        {!token ? (
          <Box
            display="flex"
            sx={{
              marginLeft: '48px',
              alignItems: 'center',
            }}
          >
            <Box display={'flex'} alignItems={'center'}>
              <ButtonMui
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                  minWidth: 'auto',
                  padding: 0,
                  '&:hover': {
                    opacity: 0.7,
                  },
                }}
              >
                <Box component={'img'} src={globe02} alt="globe" />
              </ButtonMui>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                sx={{
                  '& .MuiMenu-paper': {
                    backgroundColor: theme.palette.base.white,
                    boxShadow: '0px 1px 19px 0px rgba(0, 0, 0, 0.13)',
                    borderRadius: '8px',
                    top: '65px !important',
                    width: '55px',
                    height: 'auto',
                    padding: '16px !important',
                    paddingTop: '0px !important',
                    paddingBottom: '0px !important',
                  },
                }}
              >
                {dropdownLng.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      handleChangeLanguage(item.lng);
                      handleClose();
                    }}
                    sx={{
                      padding: '0px !important',
                      '&:not(:last-child)': {
                        marginBottom: '12px !important',
                      },
                    }}
                  >
                    <Typography
                      variant={'body3'}
                      fontWeight={600}
                      fontStyle={'normal'}
                      color={theme.palette.darkGrey[700]}
                    >
                      {item.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              marginLeft={'32px'}
              sx={{
                cursor: 'pointer',
                position: 'relative',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  bottom: 0,
                  height: '1px',
                  backgroundColor: 'currentcolor',
                  transform: 'scaleX(0)',
                  transition: 'transform 0.3s ease',
                },
                '&:hover:after': {
                  transform: 'scaleX(1)',
                },
              }}
            >
              <ButtonMui
                onClick={() => navigate('/prisijungimas')}
                sx={{
                  justifyContent: 'flex-start',
                  display: 'flex',
                  alignItems: 'center',
                  padding: 0,
                  minWidth: 'auto',
                  fontWeight: 600,
                }}
              >
                <Typography
                  fontWeight={600}
                  color={theme.palette.base.white}
                  variant={'body3'}
                  padding={0}
                >
                  {t('login')}
                </Typography>
              </ButtonMui>
            </Box>
            <Box marginLeft={'32px'}>
              <Button
                size="small"
                buttontype="orange"
                onClick={() => navigate('/registracija')}
              >
                {t('signup')}
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            display={{ xs: 'none', md: 'flex' }}
            sx={{
              marginLeft: '48px',
              alignItems: 'center',
            }}
            justifyContent={'flex-end'}
          >
            <Notification />
            <Box marginLeft={'32px'} display={'flex'} alignItems={'center'}>
              <ButtonMui
                id="basic-button"
                aria-controls={lngOpen ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={lngOpen ? 'true' : undefined}
                onClick={handleLngClick}
                sx={{
                  minWidth: 'auto',
                  padding: 0,
                  '&:hover': {
                    opacity: 0.7,
                  },
                }}
              >
                <Box component={'img'} src={globe02} alt="globe" />
              </ButtonMui>
              <Menu
                id="basic-menu"
                anchorEl={lngAnchorEl}
                open={lngOpen}
                onClose={handleLngClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                sx={{
                  '& .MuiMenu-paper': {
                    backgroundColor: theme.palette.base.white,
                    boxShadow: '0px 1px 19px 0px rgba(0, 0, 0, 0.13)',
                    borderRadius: '8px',
                    top: '65px !important',
                    width: '55px',
                    height: 'auto',
                    padding: '16px !important',
                    paddingTop: '0px !important',
                    paddingBottom: '0px !important',
                  },
                }}
              >
                {dropdownLng.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      handleChangeLanguage(item.lng);
                      handleLngClose();
                    }}
                    sx={{
                      padding: '0px !important',
                      '&:not(:last-child)': {
                        marginBottom: '12px !important',
                      },
                    }}
                  >
                    <Typography
                      variant={'body3'}
                      fontWeight={600}
                      fontStyle={'normal'}
                      color={theme.palette.darkGrey[700]}
                    >
                      {item.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              marginLeft={'32px'}
              display={'flex'}
              alignItems={'center'}
              onClick={() => navigate('/info')}
              sx={{ cursor: 'pointer' }}
            >
              <Box
                component={'img'}
                src={
                  currentUser.image
                    ? `https://backend.tenisopartneris.lt/public/images/${currentUser.image}`
                    : avatarWhite
                }
                alt="avatar"
                width={'24px'}
                height={'24px'}
                borderRadius={'50%'}
                sx={{
                  objectFit: 'cover',
                }}
              />
            </Box>
            <Box
              marginLeft={'12px'}
              display={'flex'}
              alignItems={'center'}
              onClick={() => navigate('/info')}
              sx={{ cursor: 'pointer' }}
            >
              <Typography
                variant={'body3'}
                fontWeight={600}
                fontStyle={'normal'}
                color={theme.palette.base.white}
              >
                {currentUser.first_name}
              </Typography>
            </Box>
            <Box marginLeft={'12px'} display={'flex'} alignItems={'center'}>
              <ButtonMui
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                  minWidth: 'auto',
                  padding: 0,
                  '&:hover': {
                    opacity: 0.7,
                  },
                }}
              >
                <Box component={'img'} src={chevronDown} alt="chevron down" />
              </ButtonMui>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                sx={{
                  '& .MuiMenu-paper': {
                    backgroundColor: theme.palette.base.white,
                    boxShadow: '0px 1px 19px 0px rgba(0, 0, 0, 0.13)',
                    borderRadius: '8px',
                    top: '60px !important',
                    width: '160px',
                    height: 'auto',
                    padding: '16px !important',
                    paddingTop: '0px !important',
                    paddingBottom: '0px !important',
                  },
                }}
              >
                {dropdownNav.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      if (item.title === 'logout') {
                        handleLogout();
                      } else {
                        navigate(item.href);
                      }
                    }}
                    sx={{
                      padding: '0px !important',
                      '&:not(:last-child)': {
                        marginBottom: '12px !important',
                      },
                    }}
                  >
                    <Typography
                      variant={'body3'}
                      fontWeight={600}
                      fontStyle={'normal'}
                      color={theme.palette.darkGrey[700]}
                    >
                      {t(item.title)}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: { xs: 'flex', sm: 'flex', md: 'none' },
          marginLeft: 'auto',
        }}
        alignItems={'center'}
      >
        <Button
          buttontype="white"
          backgroundColor="inherit"
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          sx={{
            minWidth: 'auto',
            padding: 1,
            border: 'none',
          }}
        >
          <Box
            component={'img'}
            src={hamburger}
            alt="hamburger"
            width={'29px'}
            height={'29px'}
          />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.array.isRequired,
  colorInvert: PropTypes.bool,
};

export default Topbar;
