import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useTheme } from '@mui/material/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import pages from '../../../navigation';
import Topbar from '../Topbar';
import { Typography } from '@mui/material';
import eye from 'assets/icons/eye.svg';
import racquet from 'assets/icons/racquet.svg';
import shoppingCart from 'assets/icons/shoppingCart.svg';
import users from 'assets/icons/users.svg';
import userCheck from 'assets/icons/userCheck.svg';
import searchIcon from 'assets/icons/search.svg';
import settings from 'assets/icons/settings-01.svg';
import podiumIcon from 'assets/icons/podium.svg';
import tennisBallIcon from 'assets/icons/tennis-ball.svg';
import { PartnerMatches, TournamentMatches } from './components';
import { getUserRoleFromToken } from 'api';

const drawerWidth = 256;

export default function SideMenu({ handleSidebarOpen }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState('');
  const isAdmin = getUserRoleFromToken() === 'admin' ? true : false;

  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <CssBaseline />
      <AppBar
        position={'fixed'}
        sx={{
          top: 0,
          backgroundColor: theme.palette.blue[500],
          boxShadow: '0px 4px 18px 0px rgba(51, 69, 79, 0.24)',
          zIndex: 300,
        }}
        elevation={trigger ? 1 : 0}
      >
        <Box
          paddingY={0}
          paddingLeft={{ xs: '16px', sm: '32px', md: '64px' }}
          paddingRight={{ xs: '16px', sm: '32px', md: '64px' }}
          paddingTop="20px"
          paddingBottom="20px"
          height={'84px'}
        >
          <Topbar onSidebarOpen={handleSidebarOpen} pages={pages} />
        </Box>
      </AppBar>
      <Drawer
        sx={{
          display: { md: 'block', xs: 'none' },
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            position: 'fixed',
            top: '84px',
            height: 'calc(100vh - 84px) !important',
            backgroundColor: theme.palette.darkGrey[100],
            border: 'none',
            boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.17)',
            padding: '32px 16px 0px 16px',
            zIndex: 100,
            overflowY: 'auto',
            overflowX: 'hidden',
            '::-webkit-scrollbar': {
              width: '1px',
              borderRadius: '2px',
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'transparent',
              borderRadius: '2px',
            },
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <List>
          {pages
            .filter((page) => page.sideBar)
            .map((page, index) => (
              <ListItem
                key={index}
                disablePadding
                sx={{
                  borderRadius: '8px',
                  backgroundColor: activeLink.includes(page.href)
                    ? theme.palette.base.white
                    : 'inherit',
                }}
              >
                <ListItemButton
                  sx={{
                    height: '40px',
                    marginBottom: '2px',
                    marginTop: '2px',
                    '&:hover': {
                      borderRadius: '8px',
                      backgroundColor: theme.palette.base.white,
                    },
                  }}
                  onClick={() => navigate(page.href)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 'auto',
                      marginRight: '16px',
                      color: theme.palette.darkGrey[700],
                    }}
                  >
                    {index === 0 && <img src={eye} alt="eye" />}
                    {index === 1 && <img src={racquet} alt="racquet" />}
                    {index === 2 && <img src={userCheck} alt="userCheck" />}
                    {index === 3 && (
                      <img src={shoppingCart} alt="shoppingCart" />
                    )}
                    {/* {index === 4 && <img src={users} alt="users" />} */}
                    {index === 4 && (
                      <img src={podiumIcon} alt="podium" width={20} />
                    )}
                    {index === 5 && <img src={searchIcon} alt="searchIcon" />}
                    {index === 6 && (
                      <img
                        src={tennisBallIcon}
                        alt="tennisBallIcon"
                        width={20}
                      />
                    )}
                  </ListItemIcon>
                  <Typography
                    variant={'body3'}
                    color={theme.palette.darkGrey[700]}
                    fontWeight={600}
                  >
                    {t(page.title)}
                  </Typography>
                </ListItemButton>
              </ListItem>
            ))}
          {isAdmin && (
            <ListItem
              disablePadding
              sx={{
                borderRadius: '8px',
                backgroundColor: 'inherit',
              }}
            >
              <ListItemButton
                sx={{
                  height: '40px',
                  marginBottom: '2px',
                  marginTop: '2px',
                  '&:hover': {
                    borderRadius: '8px',
                    backgroundColor: theme.palette.base.white,
                  },
                }}
                onClick={() => navigate('/admin')}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 'auto',
                    marginRight: '16px',
                    color: theme.palette.darkGrey[700],
                    height: '20px',
                  }}
                >
                  <img src={settings} alt="settings" />
                </ListItemIcon>
                <Typography
                  variant={'body3'}
                  color={theme.palette.darkGrey[700]}
                  fontWeight={600}
                >
                  Admin
                </Typography>
              </ListItemButton>
            </ListItem>
          )}
        </List>
        <TournamentMatches />
        <PartnerMatches />
      </Drawer>
    </Box>
  );
}
