import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
import logoSmall from 'assets/logo/tp-logo.svg';
import youtube from 'assets/icons/youtube.svg';
import facebook from 'assets/icons/facebook.svg';
import instagram from 'assets/icons/instagram.svg';
import appStore from 'assets/icons/appStore.svg';
import googleStore from 'assets/icons/googleStore.svg';
import pages from 'landing/layouts/navigation';
import moment from 'moment';

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box>
      <Box display={'flex'} flexDirection={'column'} height={'100%'}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box display={'flex'} flexDirection={'column'} width={'100%'}>
            <Box
              display={'flex'}
              component="a"
              href="/"
              title="TP"
              width={{ xs: 55, md: 55 }}
              marginBottom={'20px'}
            >
              <Box
                component={'img'}
                src={logoSmall}
                height={1}
                width={1}
                alt="Teniso Partneris logo"
              />
            </Box>
            <Box
              display={'flex'}
              maxWidth={'320px'}
              width={'100%'}
              marginBottom={'36px'}
            >
              <Typography variant={'body3'} color={theme.palette.darkGrey[200]}>
                {t('footerText')}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={{ xs: 'flex-start', md: 'center' }}
              marginBottom={{ xs: '8px', md: '64px' }}
              flexDirection={{ xs: 'column', md: 'row' }}
            >
              {pages
                .filter((item) => item.footer)
                .map((item, i) => (
                  <Box
                    key={i}
                    marginRight={{
                      xs: 0,
                      md:
                        i === pages.filter((item) => item.footer).length - 1
                          ? 0
                          : '32px',
                    }}
                    marginBottom={{ xs: '24px', md: 0 }}
                    color={theme.palette.base.white}
                    onClick={() => navigate(item.href)}
                    sx={{
                      cursor: 'pointer',
                      position: 'relative',
                      '&:after': {
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        height: '1px',
                        backgroundColor: 'currentcolor',
                        transform: 'scaleX(0)',
                        transition: 'transform 0.3s ease',
                      },
                      '&:hover:after': {
                        transform: 'scaleX(1)',
                      },
                    }}
                  >
                    <Typography
                      variant={'body3'}
                      color={theme.palette.base.white}
                      fontWeight={600}
                    >
                      {t(item.title)}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Box>
          <Box
            display={{ xs: 'none', md: 'flex' }}
            flexDirection={'column'}
            maxWidth={'136px'}
            width={'100%'}
          >
            <Box marginTop={'36px'}>
              <Typography
                variant={'body4'}
                color={theme.palette.base.white}
                fontWeight={600}
              >
                {t('getApp')}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              flexDirection={'column'}
              marginTop={'16px'}
            >
              <Box
                component={'a'}
                href={
                  'https://apps.apple.com/lt/app/teniso-partneris/id1606964818'
                }
                target="_blank"
                height={'40px'}
                width={1}
              >
                <Box
                  component={'img'}
                  src={appStore}
                  alt="Teniso Partneris app store"
                />
              </Box>
              <Box
                component={'a'}
                href={
                  'https://play.google.com/store/apps/details?id=com.tp.TenisoPartneris'
                }
                target="_blank"
                height={'40px'}
                width={1}
                marginTop={'16px'}
              >
                <Box
                  component={'img'}
                  src={googleStore}
                  alt="Teniso Partneris google store"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          borderTop={`1px solid ${theme.palette.darkGrey[200]}`}
          paddingTop={'32px'}
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={'32px'}
            width={{ xs: '100%', md: 'auto' }}
          >
            <Typography variant="body3" color={theme.palette.base.white}>
              © {moment().format('YYYY')} Teniso Partneris. All rights reserved.
            </Typography>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={'32px'}
            width={{ xs: '100%', md: 'auto' }}
            marginTop={{ xs: '32px', md: 0 }}
          >
            <Box
              component={'a'}
              href={'https://www.youtube.com/@tenisopartneris1446'}
              target="_blank"
              height={1}
              width={{ xs: '24px', md: 'auto' }}
            >
              <Box
                component={'img'}
                src={youtube}
                alt="Teniso Partneris youtube"
              />
            </Box>
            <Box
              component={'a'}
              href={'https://www.facebook.com/groups/tenisopartneris'}
              target="_blank"
              height={1}
              width={{ xs: '24px', md: 'auto' }}
            >
              <Box
                component={'img'}
                src={facebook}
                alt="Teniso Partneris facebook"
              />
            </Box>
            <Box
              component={'a'}
              href={'https://www.instagram.com/tenisopartneris/'}
              target="_blank"
              height={1}
              width={{ xs: '24px', md: 'auto' }}
            >
              <Box
                component={'img'}
                src={instagram}
                alt="Teniso Partneris instagram"
              />
            </Box>
          </Box>
        </Box>
        <Box display={'flex'} marginTop={'24px'}>
          <Typography variant="body3" color={theme.palette.base.white}>
            Website made by{' '}
            <a
              href="https://k-design.io/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: theme.palette.base.white }}
            >
              K-Design
            </a>{' '}
            and{' '}
            <a
              href="https://tkuprinskas.lt"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: theme.palette.base.white }}
            >
              tkuprinskas.lt
            </a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
