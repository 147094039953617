import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import locationIcon from 'assets/icons/location.svg';
import racquet from 'assets/icons/racquet.svg';
import phoneIcon from 'assets/icons/phone.svg';
import emailIcon from 'assets/icons/email.svg';
import orangeCheck from 'assets/icons/orangeCheck.svg';
import urlFor from 'utils/image';

const CoachCard = ({ data }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const {
    name,
    email,
    phone,
    city,
    coachingSertificate,
    coachingExperience,
    coachingType,
    image,
  } = data;

  return (
    <Box
      width={'100%'}
      height={'100%'}
      maxHeight={{ xs: '100%', sm: '480px' }}
      display={'flex'}
      justifyContent={'space-between'}
      flexDirection={{ xs: 'column-reverse', sm: 'row' }}
      sx={{
        background: theme.palette.darkGrey[50],
        borderRadius: '16px',
        overflow: 'hidden',
        boxShadow: theme.shadows[1],
      }}
    > 
    {image ? (
      <Box
        component={'img'}
        src={urlFor(image)}
        alt="coach image"
        width={{ xs: '100%', sm: '320px' }}
        sx={{
          objectFit: 'cover',
          borderTopLeftRadius: { xs: '0px', sm: '16px', md: '16px' },
          borderBottomLeftRadius: { xs: '16px', sm: '16px', md: '16px' },
          borderTopRightRadius: { xs: '0px', sm: '0px', md: '0px' },
          borderBottomRightRadius: { xs: '16px', sm: '0px', md: '0px' },
        }}
      />
    ) : (
      <Box
      width={{ xs: '100%', sm: '320px' }}
      height={{ xs: '100%', sm: '100%' }}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      sx={{
        backgroundColor: theme.palette.darkGrey[100],
        borderTopLeftRadius: { xs: '0px', sm: '16px', md: '16px' },
        borderBottomLeftRadius: { xs: '16px', sm: '16px', md: '16px' },
        borderTopRightRadius: { xs: '0px', sm: '0px', md: '0px' },
        borderBottomRightRadius: { xs: '16px', sm: '0px', md: '0px' },
      }}
    >No Image</Box>
    )}

      <Box
        sx={{
          padding: '16px',
          width: { xs: '100%', sm: '50%' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
        }}
      >
        <Typography
          variant={isMd ? 'h5' : 'h6'}
          color={theme.palette.darkGrey[700]}
          sx={{
            fontWeight: 700,
            letterSpacing: '-0.72px',
            marginBottom: '8px',
          }}
        >
          {name}
        </Typography>
        <Box display={'flex'} alignItems={'start'}>
          <Box
            component={'img'}
            src={locationIcon}
            height="20px"
            borderRadius={'50%'}
            alt="location"
          />
          <Box
            display={'flex'}
            alignItems={'start'}
            flexDirection={'column'}
            justifyContent={'start'}
          >
            <Typography
             variant={'body3'}
              fontWeight={600}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[700]}
            >
              {t('city')}:
            </Typography>
            <Typography
              variant={'body3'}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[600]}
            >
              {city}
            </Typography>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'start'} marginTop={'6px'}>
          <Box
            component={'img'}
            src={phoneIcon}
            height="20px"
            borderRadius={'50%'}
            alt="phone"
          />
          <Box
            display={'flex'}
            alignItems={'start'}
            flexDirection={'column'}
            justifyContent={'start'}
          >
            <Typography
              variant={'body3'}
              fontWeight={600}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[700]}
            >
              {t('phoneNumber')}
            </Typography>
            <a
              href={`tel:${phone}`}
              style={{
                textDecoration: 'none',
                lineHeight: 1,
              }}
            >
              <Typography
                variant={'body3'}
                marginLeft={'8px'}
                color={theme.palette.darkGrey[600]}
              >
                {phone}
              </Typography>
            </a>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'start'} marginTop={'6px'}>
          <Box
            component={'img'}
            src={emailIcon}
            height="20px"
            borderRadius={'50%'}
            alt="email"
          />
          <Box
            display={'flex'}
            alignItems={'start'}
            flexDirection={'column'}
            justifyContent={'start'}
          >
            <Typography
              variant={'body3'}
              fontWeight={600}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[700]}
            >
              {t('email')}
            </Typography>
            <a
              href={`mailto:${email}`}
              style={{
                textDecoration: 'none',
                lineHeight: 1,
              }}
            >
              <Typography
                variant={'body3'}
                marginLeft={'8px'}
                color={theme.palette.darkGrey[600]}
              >
                {email}
              </Typography>
            </a>
          </Box>
        </Box>
        <Box
          display={'flex'}
          alignItems={'start'}
          marginTop={'12px'}
        >
          <Box
            component={'img'}
            src={orangeCheck}
            alt="check"
            width={28}
            height={28}
          />
          <Box
            display={'flex'}
            alignItems={'start'}
            flexDirection={'column'}
            justifyContent={'start'}
          >
            <Typography
              variant={'body4'}
              fontWeight={600}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[700]}
            >
              {t('coachingSertificate')}
            </Typography>
            <Typography
             variant={'body4'}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[600]}
            >
              {coachingSertificate ? coachingSertificate : t('no')}
            </Typography>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'start'} marginTop={'6px'}>
          <Box
            component={'img'}
            src={orangeCheck}
            alt="check"
            width={28}
            height={28}
          />
          <Box
            display={'flex'}
            alignItems={'start'}
            flexDirection={'column'}
            justifyContent={'start'}
          >
            <Typography
             variant={'body4'}
              fontWeight={600}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[700]}
            >
              {t('coachingExperience')}
            </Typography>
            <Typography
             variant={'body4'}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[600]}
            >
              {coachingExperience}
            </Typography>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'start'} marginTop={'6px'}>
          <Box
            component={'img'}
            src={orangeCheck}
            alt="check"
            width={28}
            height={28}
          />
          <Box
            display={'flex'}
            alignItems={'start'}
            flexDirection={'column'}
            justifyContent={'start'}
          >
            <Typography
             variant={'body4'}
              fontWeight={600}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[700]}
            >
              {t('coachingType')}
            </Typography>
            <Typography
             variant={'body4'}
              marginLeft={'8px'}
              color={theme.palette.darkGrey[600]}
            >
              {coachingType}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CoachCard;
