import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'landing/layouts/Main';
import Container from 'components/Container';
import { Hero, CoachCard } from './components';
import client from 'utils/client';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Cities } from 'constants/Selections';
import { SelectInput } from 'components/Inputs';

const CoachesPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [coachesData, setCoachesData] = useState([]);
  const [open, setOpen] = useState({});
  const [filters, setFilters] = useState({
    city: '',
  });

  useEffect(() => {
    const fetchCoaches = async () => {
      const result = await client.fetch(
        `*[_type == "coaches" && isActive == true] | order(publishedAt desc) {
            name,
            email,
            phone,
            city,
            coachingSertificate,
            coachingExperience,
            coachingType,
            image,
            isActive
          }`,
      );
      setCoachesData(result);
    };
    fetchCoaches();
  }, []);

  const handleOpen = (id) => {
    setOpen({ ...open, [id]: true });
  };

  const handleClose = (id) => {
    setOpen({ ...open, [id]: false });
  };

  const handleChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  const filteredCoaches = coachesData.filter((coach) =>
    filters.city ? coach.city === filters.city : true,
  );

  return (
    <Main>
      <Box
        sx={{
          background: theme.palette.lightGrey[200],
          height: { xs: 'auto', sm: 450 },
        }}
      >
        <Container
          sx={{
            paddingBottom: {
              sm: '80px !important',
              md: '96px !important',
            },
            paddingTop: {
              sm: '0px !important',
              md: '0px !important',
            },
          }}
        >
          <Hero />
        </Container>
      </Box>
      <Container
        sx={{
          paddingBottom: {
            xs: '0px !important',
            sm: '0px !important',
            md: '0px !important',
          },
          paddingTop: {
            xs: '16px !important',
            sm: '24px !important',
            md: '32px !important',
          },
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          maxWidth={{ xs: '100%', sm: '298px', md: '416px' }}
          width={'100%'}
        >
          <Typography
            variant="body4"
            fontWeight={500}
            color={theme.palette.darkGrey[700]}
          >
            {t('selectCity')}
          </Typography>
          <SelectInput
            name="city"
            id="city"
            placeholder={t('selectCity')}
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
            handleChange={handleChange}
            topMargin={'6px'}
            options={Cities}
            value={filters.city}
          />
        </Box>
      </Container>
      <Container
        sx={{
          paddingBottom: {
            sm: '80px !important',
            md: '96px !important',
          },
          paddingTop: {
            sm: '32px !important',
            md: '48px !important',
          },
        }}
      >
        {filteredCoaches.length === 0 ? (
          <Typography variant="h6" color={theme.palette.darkGrey[700]}>
            {t('noCoaches')}
          </Typography>
        ) : (
          <Grid container spacing={4}>
            {filteredCoaches.map((data) => (
              <Grid item xs={12} md={6} key={data.name}>
                <CoachCard data={data} />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Main>
  );
};

export default CoachesPage;
