import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import coachImg from 'assets/images/coach.jpg';
import Button from 'components/Button';

const Hero = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  return (
    <Grid
      sx={{
        height: { xs: 'auto', sm: 450 },
      }}
      container
      direction={isSm ? 'row' : 'column-reverse'}
    >
      <Grid
        item
        container
        alignItems={'center'}
        justifyContent={'flex-end'}
        xs={12}
        sm={6}
      >
        <Box data-aos={isMd ? 'fade-right' : 'fade-up'} maxWidth={528}>
          <Typography
            variant="h4"
            color={theme.palette.darkGrey[700]}
            fontWeight={700}
            sx={{
              letterSpacing: '-0.72px',
              marginBottom: { xs: '12px', md: '8px' },
            }}
          >
            {t('coachesTitle')}
          </Typography>
          <Typography
            variant="body2"
            color={theme.palette.darkGrey[600]}
            fontWeight={400}
          >
            {t('coachesSubtitle')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          height={1}
          width={1}
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          ml={{ xs: '0px', sm: '104px', md: '144px' }}
          mb={{ xs: '48px', sm: '0px', md: '0px' }}
        >
          <Box
            height={1}
            width={1}
            maxWidth={{ xs: '100%', sm: '300px', md: '417px' }}
            maxHeight={{ xs: '100%', sm: '239px', md: '333px' }}
          >
            <Box
              component={'img'}
              src={coachImg}
              alt="Ball machine"
              width={1}
              height={1}
              sx={{
                objectFit: 'cover',
                borderRadius: 2,
                boxShadow: 4,
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Hero;
